import { Apientities } from '@/axios'

export const getEntities = () => {
    return Apientities.getAll('get_entities')
}

export const saveEntitie = (data) => {
    return Apientities.post('save_entitie', data)
}

export const deleteEntitie = (data) => {
    return Apientities.post('delete_entitie', data)
}