<template>
  <div>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container modal-container__inputs">
          <div class="close" @click="$emit('update',true)">X</div>
          <div class="row" v-if="type==='firstlayer' && dados">
            <div class="col-md-12 modal-container__inputs" >
                
                <label>Descrição</label>
                <input type="text" class="form-control" v-model="dados.object.desc">
                
                <label>Tipo</label>
                <input type="text" class="form-control" v-model="dados.object.type">

                <label>Cliente</label>
                <select class="form-control" v-model="dados.object.entity_type_index">
                    <option v-for="(cust,key) in clients" :value="cust.index" :key="key">{{cust.desc}}</option>
                </select>
            </div>
          </div>
          <div class="row" v-if="type==='secondlayer' && dados">
            <div class="col-md-12 modal-container__inputs" >
                <label>Descrição</label>
                <textarea class="form-control" v-model="dados.object.desc"></textarea>
                <label>Tipo</label>
                <input type="text" class="form-control" v-model="dados.object.type">
               
            </div>
          </div>
          <div class="row" v-if="type==='thirdlayer' && dados">
            <div class="col-md-12 modal-container__inputs" >
                
                <label>Tipo</label>
                <input type="text" class="form-control" v-model="dados.object.type">
                
                <label>Tipo de variável</label>
                <input type="text" class="form-control" v-model="dados.object.var_type">
                
                <label>Descrição</label>
                <textarea class="form-control" v-model="dados.object.desc"></textarea>

                <label>Multiple</label>
                <select class="form-control" v-model="dados.object.multiple">
                    <option :value="true">Verdadeiro</option>
                    <option :value="false">Falso</option>
                </select>

                <template v-if="type==='thirdlayer'">
                  <div class="modal-container__layer">
                    <label>Dados adicionais</label>
                  </div>
                <div class="modal-container__layer">
                  <div class="modal-container__inputs">
                    <label>Descrição</label>
                    <input type="text" class="form-control" v-model="child.desc">
                  </div>
                  <div class="modal-container__inputs">
                    <label>Múltiplo</label>
                    <select class="form-control" v-model="child.multiple">
                      <option :value="true">Verdadeiro</option>
                      <option :value="false">Falso</option>
                    </select>
                  </div>
    
                  <div class="modal-container__inputs">
                    <label>Ner Type</label>
                    <input type="text" class="form-control" v-model="child.ner_type">
                  </div>

                  <div class="modal-container__inputs">
                    <label>Type</label>
                    <input type="text" class="form-control" v-model="child.type">
                  </div>
                  <div class="modal-container__inputs">
                    <label>Var type</label>
                    <input type="text" class="form-control" v-model="child.var_type">
                  </div>
                  <div class="modal-container__addButton">
                    <button @click="addChild()">+</button>
                  </div>                                                      
                
                
                </div>
                <div class="modal-container__layer" v-if="dados.object.children.length > 0">
                   <div class="modal-container__inputsobject" >
                    <div class="modal-container__inputsobject--row" v-for="(dad,key) in dados.object.children" :key="key">
                    <template v-for="(dadkey,key_) in dad">
                      <div class="contentDiv" :key="key_">
                            <input v-if="key_ == 'type'" type="text" class="form-control" v-model="dad.type">
                            <input v-else-if="key_ == 'desc'" type="text" class="form-control" v-model="dad.desc">
                            <input v-else-if="key_ == 'ner_type'" type="text" class="form-control" v-model="dad.ner_type">
                            <select class="form-control" v-else-if="key_ == 'multiple'" v-model="dad.multiple">
                                <option :value="true">Verdadeiro</option>
                                <option :value="false">Falso</option>
                            </select>
                            <input v-else-if="key_ == 'var_type'" type="text" class="form-control" v-model="dad.var_type">
                           
                     </div>
                        
                    </template>
                    <div class="modal-container__removeButton">
                    <button @click="removeChild(key)">-</button>
                  </div>  

                  </div>
                  </div>
 
                </div>
                

                 


                
                </template>              



                <label>Ner Type</label> 
                
                <input type="text" class="form-control" maxlength="3" @blur="changeNer($event)">

                <template v-if="dados.object.ner_type.length > 0">
                      <span class="ner_type" v-for="(ner,key) in dados.object.ner_type" :key="key" @click="removeNer(ner)">{{ner}}</span>
                </template>
                
            </div>
          </div>
          <div class="row modal-container__button">
            <button @click="alterar()">Alterar Dados</button>
          </div>
           <div class="row modal-container__button--exclude" v-if="type != 'firstlayer'">
            <button @click="excluir()">Excluir Dado</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">

export default {
  props: {
    type:String,
  },
  name: "Form",
  components: {
    
  },
  data() {
    return {
        dados:false,
        child:{
          type:'',
          multiple:false,
          ner_type:'',
          desc:'',             
          var_type:'',
        }
    };
  },

  created() {
/*     document.addEventListener('keyup',(event)=>{
        if(event.key==='Escape') {
          this.$emit('update',true)
        }
    }) */
    
  },
  mounted() {


    
   if(this.type === 'firstlayer') {
        this.dados = {
            key:'',
            _key:'',
            __key:'',
            type:this.type,
            object:{},
            ...this.$duplicate(this.$store.getters.getFirstlayer)
        }
    }
    if(this.type === 'secondlayer') {
        this.dados = {
            key:'',
            _key:'',
            __key:'',
            type:this.type,
            object:{},
            ...this.$duplicate(this.$store.getters.getSecondlayer)
        }

     
    }
    if(this.type === 'thirdlayer') {
        this.dados = {
            key:'',
            _key:'',
            __key:'',
            type:this.type,
            object:{},
            ...this.$duplicate(this.$store.getters.getThirdlayer)
            
        }
        if(this.dados.object.children.length > 0) {
            this.dados.object.children = this.dados.object.children.map(val=>{
              val.ner_type = val.ner_type[0]
              delete val.list
              delete val.children
              delete val._id
              return val
            })
        }
        
      
    }

  },

  computed: {
     clients() {
       return this.$store.getters.getEntitiestypes
     }
  },

  watch: {},
  methods: {  
      changeNer(event) {
        let value = event.target.value
        value = value.toUpperCase();
        if(this.dados.object.multiple) {
           if(Array.isArray(this.dados.object.ner_type)) {
             this.dados.object.ner_type.push(value)
           }
           else {
              if(this.dados.object.ner_type === '') {
                this.dados.object.ner_type = []
                this.dados.object.ner_type.push(value)
              }
              else {
                 this.dados.object.ner_type = [this.dados.object.ner_type]
                 this.dados.object.ner_type.push(value)
              }
           }
           
        }
        else {
            this.dados.object.ner_type = []
            this.dados.object.ner_type.push(value)
        }
      },
      removeNer(ner) {
        this.dados.object.ner_type = this.dados.object.ner_type.filter(val=>{
            return val != ner 
        })
        .filter(val=>{
          return val != undefined
        })
      },
      alterar() {
        if(this.type === 'thirdlayer') {
          if(this.dados.object.children.length > 0) {
            this.dados.object.children = this.dados.object.children.map(val=>{
              val.ner_type = [val.ner_type]
              val.list = []
              val.children = []
              return val
            })
          }
        }
        this.$emit('updatedata',this.dados)
        this.$emit('update',true)
      },
      excluir() {
        this.$emit('excludedata',this.dados)
        this.$emit('update',true)
      },
      addChild() {
        if(this.child.type == '' || this.child.ner_type == '') return this.$toast.error('Os campos não podem estar vazios') 
        this.dados.object.children.push(this.child)
        this.child = {
          desc:'',
          multiple:false,
          type:'',          
          ner_type:[],
          var_type:'',
       }
      },
      removeChild(key) {
        delete this.dados.object.children[key]
        this.dados.object.children = this.dados.object.children.filter(val=>{
          return val != undefined
        })

      },

  },
};
</script>
<style src="./modal.less" lang="less" />
