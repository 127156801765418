import {
    getEntities,
    saveEntitie,
    deleteEntitie
} from "../../services/entities.services";

export const EntitiesMixin = {
    data() {
        return {
            entities: [],
            entities_types: [],
            modalopen: false,
            modaltype: ''
        };
    },
    created() {},
    mounted() {
        this.getEntitiesfromdb()

    },
    filters: {},
    watch: {


    },
    computed: {

    },
    methods: {
        async getEntitiesfromdb() {
            try {
                this.loading = true
                this.entities = []
                const { data } = await getEntities();

                let entstring = JSON.stringify(data.data.entities)

                let oid = new RegExp(/oid/, 'g');
                entstring = entstring.replace(oid, '');

                let sifremove = /\$+/
                let sif = new RegExp(sifremove, 'g');
                entstring = entstring.replace(sif, '_id');

                this.entities = JSON.parse(entstring)
                this.entities.map(val => {
                    val.enabled = false
                    return val
                })
                this.entities_types = this.$duplicate(data.entities_types)
                this.$store.dispatch('setEntitiestypes', this.entities_types)

            } catch (e) {
                this.$tokentest(e.response.status)
                console.log(e)
            }
        },

        editFirstlayer(key, ent) {
            this.$store.dispatch('setFirstlayer', {
                key: key,
                object: ent,
                returned: {}
            })
            this.modalopen = true
            this.modaltype = 'firstlayer'
        },



        editSecondlayer(key, _key, ent) {
            this.$store.dispatch('setSecondlayer', {
                key: key,
                _key: _key,
                object: ent,
                returned: {}
            })
            this.modalopen = true
            this.modaltype = 'secondlayer'
        },

        editThirdlayer(key, _key, __key, ent) {
            this.$store.dispatch('setThirdlayer', {
                key: key,
                _key: _key,
                __key: __key,
                object: ent,
                returned: {}
            })
            this.modalopen = true
            this.modaltype = 'thirdlayer'
        },

        changeData(event) {
            if (event.type === 'firstlayer') {
                this.entities[event.key] = event.object
            }
            if (event.type === 'secondlayer') {
                this.entities[event.key]['children'][event._key] = event.object
            }
            if (event.type === 'thirdlayer') {
                this.entities[event.key]['children'][event._key]['children'][event.__key] = event.object
            }
            this.$forceUpdate()
        },

        excludeData(event) {
            if (event.type === 'firstlayer') {
                this.entities = this.entities.filter((val, index) => {
                    if (index != event.key) {
                        return val
                    }
                })
            }
            if (event.type === 'secondlayer') {
                this.entities[event.key]['children'] = this.entities[event.key]['children'].filter((val, index) => {
                    if (index != event._key) {
                        return val
                    }
                })
            }
            if (event.type === 'thirdlayer') {
                this.entities[event.key]['children'][event._key]['children'] = this.entities[event.key]['children'][event._key]['children'].filter((val, index) => {
                    if (index != event.__key) {
                        return val
                    }
                })
            }
            this.$forceUpdate()
        },

        addThirdlayer(key, _key) {
            this.entities[key]['children'][_key]['children'].push({
                type: "Ipsum",
                desc: '',
                multiple: false,
                ner_type: [],
                var_type: '',
                list: [],
                children: []
            })
        },

        addSecondlayer(key) {
            this.entities[key]['children'].push({
                type: "Lorem Ipsum",
                desc: 'Lorem Ipsum dolor sit amet',
                multiple: false,
                ner_type: [],
                var_type: '',
                list: [],
                children: [{
                    type: "Ipsum",
                    desc: '',
                    multiple: false,
                    ner_type: [],
                    var_type: '',
                    list: [],
                    children: []
                }]
            })
        },

        addFirstlayer(position) {
            if (position === 'bottom')
                this.entities.push({
                    _id: {
                        _id: ''
                    },
                    entity_type_index: 1,
                    type: "__group__",
                    desc: 'Lorem Ipsum',
                    enabled: false,
                    children: [{
                        type: "Lorem Ipsum",
                        desc: 'Lorem Ipsum dolor sit amet',
                        multiple: false,
                        ner_type: [],
                        var_type: '',
                        list: [],
                        children: [{
                            type: "Ipsum",
                            desc: '',
                            multiple: false,
                            ner_type: [],
                            var_type: '',
                            list: [],
                            children: []
                        }]
                    }]
                })
            else {
                this.entities.unshift({
                    _id: {
                        _id: ''
                    },
                    entity_type_index: 1,
                    type: "__group__",
                    desc: 'Lorem Ipsum',
                    enabled: false,
                    children: [{
                        type: "Lorem Ipsum",
                        desc: 'Lorem Ipsum dolor sit amet',
                        multiple: false,
                        ner_type: [],
                        var_type: '',
                        list: [],
                        children: [{
                            type: "Ipsum",
                            desc: '',
                            multiple: false,
                            ner_type: [],
                            var_type: '',
                            list: [],
                            children: []
                        }]
                    }]
                })
            }
        },

        enableTab(key) {
            for (const i in this.entities) {
                if (key == i) {
                    if (this.entities[i]['enabled']) this.entities[i]['enabled'] = false
                    else this.entities[i]['enabled'] = true
                } else {
                    this.entities[i]['enabled'] = false
                }
            }
            this.$forceUpdate()
        },

        closeModal() {
            this.modalopen = false
        },

        async saveEntitie(entitie, id) {

            let confirm = window.confirm('Você deseja mesmo salvar a entidade?')

            if (confirm) {
                let saveentitie = this.$duplicate(entitie)
                delete saveentitie._id
                delete saveentitie.enabled
                console.log(saveentitie)
                let obj = {
                    id: id,
                    entitie: saveentitie
                }

                try {
                    const { data } = await saveEntitie(obj)
                    let entstring = JSON.stringify(data.data.entities)

                    let oid = new RegExp(/oid/, 'g');
                    entstring = entstring.replace(oid, '');

                    let sifremove = /\$+/
                    let sif = new RegExp(sifremove, 'g');
                    entstring = entstring.replace(sif, '_id');

                    this.entities = JSON.parse(entstring)
                    this.entities.map(val => {
                        val.enabled = false
                        return val
                    })
                    this.entities_types = this.$duplicate(data.entities_types)
                    this.$store.dispatch('setEntitiestypes', this.entities_types)
                    this.$toast.success(data.msg)
                } catch (e) {
                    this.$tokentest(e.response.status)
                    this.$toast.error(e.response.msg)
                    console.log(e.response.msg)
                }
            } else {
                return
            }
        },


        async deleteEntitie(id) {

            let confirm = window.confirm('Você deseja mesmo excluir a entidade?')

            if (confirm) {
                let obj = {
                    id: id,
                }

                try {
                    const { data } = await deleteEntitie(obj)
                    let entstring = JSON.stringify(data.data.entities)

                    let oid = new RegExp(/oid/, 'g');
                    entstring = entstring.replace(oid, '');

                    let sifremove = /\$+/
                    let sif = new RegExp(sifremove, 'g');
                    entstring = entstring.replace(sif, '_id');

                    this.entities = JSON.parse(entstring)
                    this.entities.map(val => {
                        val.enabled = false
                        return val
                    })
                    this.entities_types = this.$duplicate(data.entities_types)
                    this.$store.dispatch('setEntitiestypes', this.entities_types)
                    this.$toast.success(data.msg)
                } catch (e) {
                    this.$tokentest(e.response.status)
                    this.$toast.error(e.response.msg)
                    console.log(e.response.msg)
                }
            } else {
                return
            }

        }



    },
};