<template>
  <div class="container-fluid entities">
    <Modal
      v-if="modalopen"
      @update="closeModal()"
      @updatedata="changeData($event)"
      @excludedata="excludeData($event)"
      :type="modaltype"
    />
    <div class="container entities__container" v-if="entities.length > 0">
      <button @click="addFirstlayer('top')">Adicionar +</button>
      <template v-for="(ent, key) in entities">
        <div class="entities__container--layerFirst" :key="key">
          <div
            @click="editFirstlayer(key, ent)"
            class="layerFirst__headerInfo title"
          >
            <h1>{{ ent.desc }}</h1>
          </div>
          <div class="layerFirst__headerInfo" @click="enableTab(key)"> 
            <div>{{ ent.type }}</div>
            <div>{{ ent.entity_type_index }}</div>
          </div>
          
          <template v-if="ent.enabled">
            <div
              class="entities__container--layerSecond"
              v-for="(_ent, _key) in ent.children"
              :key="_key"
            >
              <h2 @click="editSecondlayer(key, _key, _ent)">{{ _ent.desc }}</h2>
              <h3 @click="editSecondlayer(key, _key, _ent)">{{ _ent.type }}</h3>

              <template v-if="_ent.children.length > 0">
                <span
                  class="shadow-sm"
                  v-for="(__ent, __key) in _ent.children"
                  :key="__key"
                  @click="editThirdlayer(key, _key, __key, __ent)"
                >
                  {{ __ent.desc }}
                  {{ __ent.type }}
                </span>

              </template>
                              <span
                  class="shadow-sm add"
                  @click="addThirdlayer(key, _key)"
                  >+</span
                >
            </div>
           <button @click="addSecondlayer(key)">Adicionar +</button>
          </template>
          <div class="layerFirst__Button">
            <button class="save" @click="saveEntitie(ent,ent._id._id)">Salvar Entidade</button>
          </div>
          <div class="layerFirst__Button" >
            <button class="exclude" @click="deleteEntitie(ent._id._id)">Excluir Entidade</button>
          </div>
           
        </div>
       
      </template>
       <button @click="addFirstlayer('bottom')">Adicionar +</button>

    </div>
  </div>
</template>
<script type="text/javascript">
import { EntitiesMixin } from "./Entities.mixin.js";
import Modal from "../../components/ui/modal/modal.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {};
  },
  watch: {},
  props: {},
  components: {
    Modal,
  },
  name: "entities",
  mixins: [EntitiesMixin],
};
</script>
<style src="./Entities.less" lang="less" />
